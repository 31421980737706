export const METADATA_IMAGE_DIMENSIONS = {
  FACEBOOK: {
    width: 1200,
    height: 630
  },
  TWITTER: {
    width: 1500,
    height: 750
  }
};
